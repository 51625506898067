import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import WCFilters from 'components/wcfilter';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <div style={{ width: '100%' }}>
      <WCFilters
        items={data.homeJson.wcfilter}
        googlemap={data.homeJson.googlemap}
        dayStyles={data.homeJson.googlemap.dayStyles}
        currentLocationButton={data.homeJson.currentLocation}
      />
    </div>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      wcfilter {
        title
        machineName
        iconSource
        iconSourceActive
      }
      currentLocation {
        title
        iconSource
        iconSourceActive
      }
      googlemap {
        apiKey
        zoom
        center {
          lat
          lng
        }
        region
        language
        dayStyles {
          styles {
            elementType
            stylers {
              hue
              saturation
              gamma
              weight
              color
            }
            featureType
          }
        }
      }
    }
    allNodePage {
      edges {
        node {
          title
          body {
            value
          }
          path {
            alias
          }
        }
      }
    }
  }
`;
