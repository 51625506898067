import React from 'reactn';
import PropTypes from 'prop-types';
import GoogleMap from 'components/googlemap';
import { Container } from './wcfilter.css';

class WCFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wcType: 'toilet',
      markerIcon:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIwMCIgaGVpZ2h0PSIxMjAwIiB2aWV3Qm94PSIwIDAgMTIwMCAxMjAwIj48Zz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MDAgNjAwKSBzY2FsZSgwLjY5IDAuNjkpIHJvdGF0ZSgwKSB0cmFuc2xhdGUoLTYwMCAtNjAwKSIgc3R5bGU9ImZpbGw6IzQxNDE0MSI+PHN2ZyBmaWxsPSIjNDE0MTQxIiB4bWxuczp4PSJodHRwOi8vbnMuYWRvYmUuY29tL0V4dGVuc2liaWxpdHkvMS4wLyIgeG1sbnM6aT0iaHR0cDovL25zLmFkb2JlLmNvbS9BZG9iZUlsbHVzdHJhdG9yLzEwLjAvIiB4bWxuczpncmFwaD0iaHR0cDovL25zLmFkb2JlLmNvbS9HcmFwaHMvMS4wLyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCAxMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48ZyBpOmV4dHJhbmVvdXM9InNlbGYiPjxnPjxwYXRoIGQ9Ik0zMy45LDQwLjNjLTAuNy01LjctNS42LTEwLjEtMTEuNC0xMC4xaC02LjVjLTUuOCwwLTEwLjcsNC4zLTExLjQsMTAuMUwyLjUsNThjLTAuMywyLjQsMS42LDQuNiw0LDQuNmgyLjlsMS42LDMwLjQgICAgIGMwLjEsMiwxLjgsMy42LDMuOCwzLjZoOC43YzIsMCwzLjctMS42LDMuOC0zLjZsMS42LTMwLjRoMy4yYzIuMywwLDQuMS0yLDMuOC00LjNMMzMuOSw0MC4zeiI+PC9wYXRoPjxwYXRoIGQ9Ik05Ny41LDU4LjJsLTIuMi0xOGMtMC43LTUuNy01LjYtMTAuMS0xMS40LTEwLjFoLTYuNWMtNS44LDAtMTAuNyw0LjMtMTEuNCwxMC4xTDYzLjksNThjLTAuMywyLjQsMS42LDQuNiw0LDQuNkg2OSAgICAgbC0zLjIsMTEuNWMtMSwzLjQsMS42LDYuOCw1LjIsNi44aDEuOWwwLjcsMTIuNWMwLjEsMS44LDEuNiwzLjIsMy4zLDMuMmg3LjZjMS44LDAsMy4yLTEuNCwzLjMtMy4ybDAuNy0xMi41aDEuOSAgICAgYzMuNSwwLDYuMS0zLjQsNS4yLTYuOGwtMy4yLTExLjVoMS4zQzk2LDYyLjUsOTcuNyw2MC41LDk3LjUsNTguMnoiPjwvcGF0aD48Y2lyY2xlIGN4PSIxOS4zIiBjeT0iMTUuMiIgcj0iMTEuNyI+PC9jaXJjbGU+PGNpcmNsZSBjeD0iODAuNyIgY3k9IjE1LjIiIHI9IjExLjciPjwvY2lyY2xlPjxwYXRoIGQ9Ik01Mi45LDMuNWgtNS44Yy0xLjEsMC0xLjksMC45LTEuOSwxLjl2ODkuM2MwLDEuMSwwLjksMS45LDEuOSwxLjloNS44YzEuMSwwLDEuOS0wLjksMS45LTEuOVY1LjQgICAgIEM1NC44LDQuMyw1My45LDMuNSw1Mi45LDMuNXoiPjwvcGF0aD48L2c+PC9nPjwvZz48L3N2Zz48L2c+PC9nPjwvc3ZnPg==',
      useCurrentLocation: 0,
    };
  }

  render() {
    return (
      <div>
        <Container>
          {this.props.items.map((icon, i) => (
            <button
              key={i}
              onClick={() => this.filterWCs(icon.machineName, icon.iconSource)}
            >
              <img
                src={
                  this.state.wcType === icon.machineName
                    ? icon.iconSourceActive
                    : icon.iconSource
                }
                alt={icon.title}
                title={icon.title}
              />
            </button>
          ))}
          <button onClick={() => this.toggleCurrentLocation()}>
            <img
              src={
                this.global.useCurrentLocation
                  ? this.props.currentLocationButton.iconSourceActive
                  : this.props.currentLocationButton.iconSource
              }
              alt={this.props.currentLocationButton.title}
              title={this.props.currentLocationButton.title}
            />
          </button>
        </Container>
        <>
          <GoogleMap
            googlemap={this.props.googlemap}
            wcType={this.state.wcType}
            markerIcon={this.state.markerIcon}
            dayStyles={this.props.googlemap.dayStyles}
            useCurrentLocation={this.state.useCurrentLocation}
          />
        </>
      </div>
    );
  }

  filterWCs(wcType, markerIcon) {
    this.setState({
      wcType: wcType,
      markerIcon: markerIcon,
    });
  }

  toggleCurrentLocation() {
    this.setState({ useCurrentLocation: this.state.useCurrentLocation + 1 });
  }
}

WCFilters.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  googlemap: PropTypes.object.isRequired,
  currentLocationButton: PropTypes.object,
};

export default WCFilters;
