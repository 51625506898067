import styled from 'styled-components';

export const MapContainer = styled.div`
  border: 1px solid #414141;

  .gm-style-iw {
    max-width: 350px;
    padding: 1rem;
  }

  .gm-info-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .gm-info-label {
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
  }
`;
