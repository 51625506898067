/*global google*/
import React, { Component } from 'reactn';
import PropTypes from 'prop-types';
import GoogleInfoWindow from '../infowindow/infowindow';
import { MapContainer } from './css/googlemap.css';
import MarkerClusterer from '@google/markerclusterer';

class GoogleMap extends Component {
  // Define props.
  static get propTypes() {
    return {
      googlemap: PropTypes.object,
      wcType: PropTypes.string,
      useCurrentLocation: PropTypes.number,
      onBoundsChange: PropTypes.func,
      markers: PropTypes.array,
    };
  }

  activeInfoWindow = {};
  markers = [];
  nightStyles = [];
  dayStyles = {};
  dayTime = {};
  markersClusterer = {};
  // coordinates shift for caching
  LAT_SHIFT = 0.0117;
  LNG_SHIFT = 0.078564;

  state = {
    googlemap: false,
    currentLocation: {},
    cachedBounds: {},
  };

  constructor(props) {
    super(props);
    this.dayStyles = props.googlemap.dayStyles;
    this.nightStyles = props.googlemap.nightStyles;
    if (typeof window !== 'undefined') {
      // this._getDaytime();
    }
  }

  // Component loaded event.
  componentDidMount = () => {
    window.initMap = this.initMap;
    this._loadJS(
      'https://maps.googleapis.com/maps/api/js?key=' +
        this.props.googlemap.apiKey +
        '&callback=initMap&region=' +
        this.props.googlemap.region +
        '&language=' +
        this.props.googlemap.language
    );
  };

  componentDidUpdate(prevState) {
    if (prevState.wcType !== this.props.wcType) {
      this._boundsChanged(true);
    }
    if (prevState.useCurrentLocation !== this.props.useCurrentLocation) {
      this._askForLocation();
    }
  }

  // Init google map.
  initMap = () => {
    let object = this;
    const map = new google.maps.Map(document.getElementById('wtpmap'), {
      center: new google.maps.LatLng(
        this.props.googlemap.center.lat,
        this.props.googlemap.center.lng
      ),
      zoom: this.props.googlemap.zoom,
      minZoom: 12,
      mapTypeControl: false,
    });

    this.setState({
      googlemap: map,
    });
    this.state.googlemap.setOptions(this.dayStyles);
    // this._askForLocation();
    google.maps.event.addListener(map, 'idle', function() {
      object._boundsChanged();
    });
  };

  // Get daytime.
  _getDaytime = () => {
    let object = this;
    /**
     * @TODO: Implement caching.
     */
    window
      .fetch(
        'https://api.sunrise-sunset.org/json?lat=' +
          object.props.googlemap.center.lat +
          '&lng=' +
          object.props.googlemap.center.lng +
          '&formatted=0'
      )
      .then(function(response) {
        return response.json();
      })
      .then(function(dayTimeResponse) {
        object.dayTime = dayTimeResponse.results;
        object._applyStyles();
      })
      .catch(error => {
        throw error;
      });
  };

  // Ask user to share location.
  _askForLocation() {
    const obj = this;
    const googlemap = this.state.googlemap;
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          googlemap.setCenter(pos);
          obj.setGlobal({
            useCurrentLocation: true,
          });
          obj.setState({
            currentLocation: googlemap.getCenter(),
          });
        },
        function() {}
      );
    }
  }

  // User moved or zoomed map.
  _boundsChanged(update = false) {
    let bounds = this.state.googlemap.getBounds();
    const mapBounds = {
      ne: {
        lat: parseFloat(
          bounds
            .getNorthEast()
            .lat()
            .toString()
        ),
        lng: parseFloat(
          bounds
            .getNorthEast()
            .lng()
            .toString()
        ),
      },
      sw: {
        lat: parseFloat(
          bounds
            .getSouthWest()
            .lat()
            .toString()
        ),
        lng: parseFloat(
          bounds
            .getSouthWest()
            .lng()
            .toString()
        ),
      },
    };
    // Cache check.
    if (
      !update &&
      Object.keys(this.state.cachedBounds).length > 0 &&
      mapBounds.ne.lat < this.state.cachedBounds.ne.lat &&
      mapBounds.ne.lng < this.state.cachedBounds.ne.lng &&
      mapBounds.sw.lat > this.state.cachedBounds.sw.lat &&
      mapBounds.sw.lng > this.state.cachedBounds.sw.lng
    ) {
      return;
    }
    this.setState({
      cachedBounds: {
        ne: {
          lat:
            parseFloat(
              bounds
                .getNorthEast()
                .lat()
                .toString()
            ) + this.LAT_SHIFT,
          lng:
            parseFloat(
              bounds
                .getNorthEast()
                .lng()
                .toString()
            ) + this.LNG_SHIFT,
        },
        sw: {
          lat:
            parseFloat(
              bounds
                .getSouthWest()
                .lat()
                .toString()
            ) - this.LAT_SHIFT,
          lng:
            parseFloat(
              bounds
                .getSouthWest()
                .lng()
                .toString()
            ) - this.LNG_SHIFT,
        },
      },
    });
    this._clearMarkers();
    let object = this;
    let center = this.state.googlemap.getCenter();
    if (
      center.lat !== this.state.currentLocation.lat ||
      center.lng !== this.state.currentLocation.lng
    ) {
      this.setGlobal({
        useCurrentLocation: false,
      });
    }
    window
      .fetch(
        'https://wtpapi.rocks/graphql?query=' +
          '{nodeQuery (filter: {conjunction: AND,conditions: [' +
          '{field: "type", value: "wc", operator: EQUAL},' +
          '{field: "status", value: "1", operator: EQUAL},' +
          '{field: "field_wc_type", value: "' +
          this.props.wcType +
          '", operator: EQUAL}' +
          '{field: "field_location.lat", value: "' +
          this.state.cachedBounds.sw.lat +
          '", operator: GREATER_THAN}' +
          '{field: "field_location.lat", value: "' +
          this.state.cachedBounds.ne.lat +
          '", operator: SMALLER_THAN}' +
          '{field: "field_location.lng", value: "' +
          this.state.cachedBounds.sw.lng +
          '", operator: GREATER_THAN}' +
          '{field: "field_location.lng", value: "' +
          this.state.cachedBounds.ne.lng +
          '", operator: SMALLER_THAN}' +
          ']}) {entities{... on NodeWc {nid, field_display_title: fieldDisplayTitle, field_location: fieldLocation{lat,lng}, field_description: fieldDescription {value}, field_price: fieldPrice, field_open_hours: fieldOpenHours {day, starthours, endhours, comment}}}}}',
        {
          method: 'GET',
          origin: 'https://wheretopiss.nl',
        }
      )
      .then(function(response) {
        return response.json();
      })
      .then(function(markersResponse) {
        if (markersResponse.data.nodeQuery.entities.length > 0) {
          markersResponse.data.nodeQuery.entities.forEach(entity => {
            // Create tooltip.
            let infoWindowData = {
              title: entity.field_display_title,
              description:
                entity.field_description !== null
                  ? entity.field_description.value
                  : '',
              price: entity.field_price !== null ? entity.field_price : '',
              openHours:
                entity.field_open_hours !== null ? entity.field_open_hours : {},
            };
            let googleTooltip = new google.maps.InfoWindow({
              content: GoogleInfoWindow.render(infoWindowData),
              disableAutoPan: true,
            });
            // Create marker.
            let wcmarker = new google.maps.Marker({
              position: new google.maps.LatLng(
                entity.field_location.lat,
                entity.field_location.lng
              ),
              title: entity.field_display_title,
              icon: {
                url: object.props.markerIcon,
                scaledSize: new google.maps.Size(50, 50),
                origin: new google.maps.Point(0, 0),
              },
              nid: entity.nid,
            });
            // Open previous active tooltip if marker is available.
            if (entity.nid === object.activeInfoWindow.placeId) {
              object.activeInfoWindow.infoWindow.open(
                object.state.googlemap,
                wcmarker
              );
            }
            // Add marker click event.
            google.maps.event.addListener(wcmarker, 'click', () => {
              if (object.activeInfoWindow.infoWindow !== undefined) {
                object.activeInfoWindow.infoWindow.close();
              }
              object.activeInfoWindow.placeId = wcmarker.nid;
              object.activeInfoWindow.infoWindow = googleTooltip;
              googleTooltip.open(object.state.googlemap, wcmarker);
            });
            // Close tooltip when clicked outside of it.
            google.maps.event.addListener(
              object.state.googlemap,
              'click',
              () => {
                googleTooltip.close();
                delete object.activeInfoWindow.infoWindow;
                object.activeInfoWindow.placeId = 0;
              }
            );
            google.maps.event.addListener(googleTooltip, 'closeclick', () => {
              delete object.activeInfoWindow.infoWindow;
              object.activeInfoWindow.placeId = 0;
            });
            wcmarker.setMap(object.state.googlemap);
            object.markers.push(wcmarker);
          });
          if (object.markers.length > 1) {
            object.markersClusterer = new MarkerClusterer(
              object.state.googlemap,
              object.markers,
              {
                styles: [
                  {
                    height: 53,
                    url: '/cluster.png',
                    width: 52,
                  },
                ],
              }
            );
          }
        }
      })
      .catch(error => {
        throw error;
      });
  }

  // Remove markers from the map.
  _clearMarkers() {
    if (Object.keys(this.markersClusterer).length !== 0) {
      this.markersClusterer.clearMarkers();
      this.markersClusterer = {};
    }
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  // Apply map styles depending on time of the day.
  _applyStyles() {
    const now = new Date();
    const mapStyles =
      now > new Date(this.dayTime.sunset) ||
      now < new Date(this.dayTime.sunrise)
        ? this.nightStyles
        : this.dayStyles;
    this.state.googlemap.setOptions(mapStyles);
  }

  // Load google maps js.
  _loadJS(src) {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  }

  render() {
    return (
      <MapContainer>
        <div id="wtpmap" style={{ height: '500px', width: '100%' }} />
      </MapContainer>
    );
  }
}

export default GoogleMap;
