class GoogleInfoWindow {
  static render(infoWindowData) {
    return (
      '<div class="gm-info-title">' +
      infoWindowData.title +
      '</div>' +
      '<div class="gm-info-descr">' +
      infoWindowData.description +
      '</div>' +
      this.getPrice(infoWindowData.price)
    );
  }

  static getPrice(price) {
    let priceText;
    if (parseFloat(price) > 0) {
      priceText =
        '<div class="gm-info-label">Price</div>' +
        '<div class="gm-info-price">&euro; ' +
        parseFloat(price).toFixed(2) +
        '</div>';
    } else {
      priceText = '';
    }
    return priceText;
  }
}

export default GoogleInfoWindow;
