import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  button {
    border: 0;
    background: none;
  }

  button:focus {
    outline: 0;
  }

  img {
    width: 60%;
    min-width: 45px;
  }

  ${MEDIA.TABLET`
    
  `};
  ${MEDIA.mobileM`
    
  `};
  ${MEDIA.mobileL`
    
  `};
`;
